import React from "react"

import styled from "styled-components"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import {
  Container,
  PageTitle,
  PostText,
  mobileWidth,
} from "../utils/common-styles"
import ogImage from "../images/image_softwareentwicklerfinden.png"

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${mobileWidth}px) {
    flex-direction: column;
    justify-content: stretch;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  @media (max-width: ${mobileWidth}px) {
    margin-bottom: 30px;
  }
  &:last-child {
    margin-right: 0;
  }
`

const NotFoundPage = () => (
  <LayoutPage

    title="404 - Entschuldigung, wir können die Seite nicht finden"
    
  >
    <SEO title="404 - Entschuldigung, wir können die Seite nicht finden" description="Entschuldigung, wir können die Seite nicht finden" image={ogImage} />
    <Container>
      <Columns>
        <Column>
          <PageTitle>Entschuldigung, wir können die Seite nicht finden</PageTitle>
          <PostText>
           
          </PostText>
        </Column>
      </Columns>
    </Container>
  </LayoutPage>
)

export default NotFoundPage
